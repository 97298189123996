<template>
  <div>
    <el-card class="x-card-title" shadow="hover" header="联系人信息设置">
      <el-form label-position="right" label-width="80px" :rules="updateContactFormRule">
        <el-form-item label="联系人" prop="link_name">
          <span>{{ shopInfo.link_name }}</span>
        </el-form-item>
        <el-form-item label="联系方式" prop="link_phone">
          <span>{{ shopInfo.link_phone }}</span> &nbsp;&nbsp;&nbsp;&nbsp;
          <el-button type="text" @click="openDialog">更换联系人</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card class="x-card-title" shadow="hover" header="退货地址设置">
      <el-form
        :model="shopDataForm"
        ref="shopDataForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="收货人" prop="refund_person">
          <el-input v-model="shopDataForm.refund_person"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="refund_tel">
          <el-input v-model="shopDataForm.refund_tel"></el-input>
        </el-form-item>

        <!-- <el-form-item label="邮政编码:" prop="refund_zip"> -->
        <!-- <el-input v-model="shopDataForm.refund_zip"></el-input> -->
        <!-- <en-region-picker :api="MixinRegionApi" :default="areas" @changed="(object) => { shopDataForm.shop_region = object.last_id }"></en-region-picker> -->
        <!-- </el-form-item> -->

        <el-form-item label="联系地址" prop="region" class="area-select">
          <en-region-picker :api="MixinRegionApi" :default="areas" @changed="handleChangeArea"></en-region-picker>
        </el-form-item>
        <el-form-item label="详细地址" prop="refund_address">
          <el-input v-model="shopDataForm.refund_address"></el-input>
        </el-form-item>


        <el-form-item>
          <el-button
            type="primary"
            size="small"
            @click="handleSaveShopData('shopDataForm')"
            style="width: 400px"
          >
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <x-dialog :proxy="updateContactDialog">
      <el-form ref="updateContactRefForm" :model="updateContactForm" :rules="updateContactFormRule" label-width="80px" style="margin-top: 20px;">
        <el-form-item label="联系人" prop="link_name">
          <el-input class="update_input" v-model="updateContactForm.link_name" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="mobile">
          <el-input class="update_input" v-model="updateContactForm.mobile" clearable></el-input>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button type="primary" id="send_sms_code" @click="sendSmsCode">获取验证码</el-button>
        </el-form-item>
        <el-form-item label="验证码" prop="sms_code">
          <el-input class="update_input" v-model="updateContactForm.sms_code" placeholder="请输入验证码" clearable></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
// import * as API_Shop from '@/api/shop'
import {UE} from '@/components'
import {RegExp} from '@/../ui-utils'
import * as API from '@/api/shopSetting'
import XDialog from '@/components/x-dialog/x-dialog';
import Storage from '@/utils/storage'
import {$xDialog} from '@/components/x-dialog/dialog.proxy';

export default {
  name: 'shopSetting',
  components: {
    [UE.name]: UE,
    XDialog
  },
  data() {

    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入联系人电话'))
      } else if (!RegExp.mobile.test(value)) {
        callback(new Error('请输入正确的11位手机号码'))
      } else {
        callback()
      }
    }

    const validQQ = (rule, value, callback) => {
      if (value && !/^[0-9]\d*$/g.test(value)) {
        callback(new Error('请输入0-9之间的整数'))
      } else {
        callback()
      }
    }
    return {
      // 地区id数组
      areas: [],
      /** 店铺信息*/
      shopDataForm: {
        refund_zip: '',
        refund_person: '',
        refund_tel: '',
        refund_address: '',
        id: ''

      },
      /** 店铺logo图片存*/
      fileList_logo: [],

      /** 店铺banner图片存*/
      fileList_banner: [],

      /** 校验规则 校验必填 */
      rules: {
        refund_zip: [
          {
            required: true,
            message: '请填写邮政编号',
            trigger: 'blur'
          }
        ],
        refund_person: [
          {
            required: true,
            message: '请填写收货人',
            trigger: 'blur'
          }
        ],
        refund_tel: [
          {
            required: true,
            message: '请填写联系方式',
            trigger: 'blur'
          },
          {
            validator: validPhone,
            trigger: 'blur'
          }
        ],
        refund_address: [{
          required: true,
          message: '请填写详细地址',
          trigger: 'blur'
        }],
        region: [
          {
            required: true,
            validator: (value, rules, callback) => {
              if (typeof this.shopDataForm.region !== 'object') {
                callback()
              } else {
                callback(new Error('请选择联系地址'))
              }
            }
          }
        ]
      },
      shopInfo: '',
      updateContactForm: {
        link_name: '',
        mobile: '',
        sms_code: ''
      },
      updateContactDialog: $xDialog.create({
        title: '更换联系人',
        beforeCancel: () => { this.updateContactDialog.dismiss() },
        beforeConfirm: () => this.save(),
        width: '35%'
      }),
      updateContactFormRule: {
        link_name: [
          {required: true, message: '请输入联系人', trigger: 'change'},
          {required: true, validator: (rule, value, callback) => {
            console.log(value)
              if (value.length > 32) {
                callback(new Error('联系人输入限制32字符以内'))
              } else {
                callback()
              }
            }, trigger: 'change'}
        ],
        mobile: [
          { required: true, message: '请输入联系方式', trigger: 'change'},
          { required: true, validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入手机号'));
              } else if (!RegExp.mobile.test(value)) {
                callback(new Error('请输入正确的手机号'));
              } else {
                callback();
              }
            }, trigger: 'change'}
        ],
        sms_code: [
          { required: true, message: '请输入验证码', trigger: 'change'}
        ],
        link_phone: [
          { required: true, message: '请输入联系方式', trigger: 'change'},
        ]
      }
    }
  },
  mounted() {
    this.getAdress();
    this.getShopInfo()
    const user = Storage.getItem('seller_user');
  },
  methods: {
    openDialog() {
      this.updateContactForm["link_name"] = this.shopInfo.link_name
      this.updateContactForm["mobile"] = this.shopInfo.link_phone
      this.updateContactDialog.display()
    },
    sendSmsCode() {
      let time1 = ''
      if (!this.updateContactForm.mobile) {
        this.$message.error('请输入手机号')
      } else {
        API.getSendSmsCode({mobile: this.updateContactForm.mobile}).then(res => {
          let num = 59;
          this.$message.success('验证码已发送，请注意查收')
          let btn = document.getElementById('send_sms_code')
          time1 = setInterval(() => {
            if (num < 1) {
              btn.innerText = '获取验证码'
              btn.disabled = false
              clearInterval(time1)
            } else {
              btn.innerText = `${num}秒后再次获取`
              btn.disabled = true
              num--;
            }
          }, 1000)
        })
      }
    },
    save() {
      if (this.$refs.updateContactRefForm.validate) {
        API.updateContactsInfo(this.updateContactForm).then(res => {
          this.$message.success('更改成功')
          this.updateContactDialog.dismiss()
          this.getShopInfo()
        })
      }
    },
    /** 操作地区选择器改变时 触发*/
    handleChangeArea(val) {
      this.shopDataForm.region = val.last_id;
    },
    handleChange(d) {
      console.log(d);
    },
    /** 获取地址信息 */
    getAdress() {
      API.getBackAdress().then(response => {
        console.log(response, '退货地址');
        this.shopDataForm = response;
        if (response.refund_province_id) {
          this.areas = [response.refund_province_id, response.refund_city_id, response.refund_county_id || -1, response.refund_town_id || -1]
        }
      })
    },
    /** 保存退货地址设置*/
    handleSaveShopData(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid)
        if (valid) {
          let _params = {...this.shopDataForm}
          console.log(_params, '_params');
          API.setBackAdress(_params.id, _params).then(() => {
            this.$message.success('保存地址设置成功')
            this.getAdress()
          })
        }
      })
    },

    // 获取店铺信息
    getShopInfo() {
      API.getShopInfo().then(res => {
        this.shopInfo = res
      })
    }

    /** 图片上传之前的校验 */
    /*handleImagesUrlBefore(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = (event) => {
          let image = new Image()
          image.onload = () => {
            let width = image.width
            let height = image.height
            if (width > 300 || width < 200) {
              this.$message.error('图片宽度必须在200~300之间！')
              reject()
            }
            if (height > 100 || height < 50) {
              this.$message.error('图片高度必须在50~100之间！')
              reject()
            }
            resolve()
          }
          image.src = event.target.result
        }
        reader.readAsDataURL(file)
      })
    },*/

    /** 上传logo成功以后*/
    /*uploadSuccessLogo(response) {
      this.fileList_logo.shift()
      this.fileList_logo.push(response)
      this.shopDataForm.shop_logo = response.url
    }*/
  }
}
</script>

<style lang="scss" scoped>
/deep/ .update_input {
  width: 240px !important;
}
/deep/ .app-address {
  height: 40px;
}

/deep/ .app-address-title {
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
}

.zz {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.shu {
  height: 30px;
  width: 6px;
  background-color: rgb(26, 67, 169);
  display: inline-block;
}

.he {

  padding-bottom: 30px;
}

.bg-settings {
  background: #fff;
  border: 1px solid #FAFAFA;
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
}

/deep/ .el-form-item--medium .el-form-item__content, .el-form-item--medium .el-form-item__label {
  line-height: 24px;
}

/deep/ .upload-demo {
  width: 80%;
}

.QQ-tip {
  font-size: 13px;
  color: #796a6a;
}

/deep/ .el-input {
  width: 400px;
}

/deep/ .app-address-title {
  width: 400px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}

/deep/ .app-address-title:hover {
  height: 40px;
  line-height: 40px;
}
</style>
